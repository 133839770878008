import { get, post } from "../services/http";

// 用户登录 /api/user/login
export function login(params = {}) {
  return post(`/api/user/login`, params);
}

// 注销登录 /api/user/logout
export function logout(params = {}) {
  return post(`/api/user/logout`, params);
}

// 用户列表 /api/user/index
export function user_index(params = {}) {
  return get(`/api/user/index`, params);
}

// 用户列表 /api/user/getlist
export function user_getlist(params = {}) {
  return get(`/api/user/getlist`, params);
}

// 获取用户详情 /api/user/getrow
export function user_getrow(params = {}) {
  return get(`/api/user/getrow`, params);
}

// 添加用户 /api/user/add
export function user_add(params = {}) {
  return get(`/api/user/add`, params);
}

// 编辑用户 /api/user/edit
export function user_edit(params = {}) {
  return get(`/api/user/edit`, params);
}

// 删除用户 /api/user/del
export function user_del(params = {}) {
  return get(`/api/user/del`, params);
}

// 重置密码 /api/user/resetpwd
export function user_resetpwd(params = {}) {
  return get(`/api/user/resetpwd`, params);
}




// 用户个人信息 /api/user/userInfo
export function userInfo(params = {}) {
  return get(`/api/user/userInfo`, params);
}

// 用户个人信息修改 /api/user/editUser
export function editUser(params = {}) {
  return get(`/api/user/editUser`, params);
}
// 安全日志 /api/user/securityLog
export function securityLog(params = {}) {
  return get(`/api/user/securityLog`, params);
}
// 安全中心-操作日志 /api/user/operateLog
export function operateLog(params = {}) {
  return get(`/api/user/operateLog`, params);
}
// 修改密码 /api/user/xgPassword
export function xgPassword(params = {}) {
  return post(`/api/user/xgPassword`, params);
}
//修改手机号 /api/user/changemobile
export function changemobile(params = {}) {
  return post(`/api/user/changemobile`, params);
}
//发送验证码 /api/sms/send
export function send(params = {}) {
  return post(`/api/sms/send`, params);
}
// 月报操作日志  /api/user/monthLog
export function monthLog(params = {}) {
  return get(`/api/user/monthLog`, params);
}
