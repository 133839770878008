import Vue from "vue";
import Vuex from "vuex";
import _interface from "./modules/interface";
import _protocol from "./modules/protocol";
import _datasource from "./modules/datasource";
import _role from "./modules/role";
import _mainThread from "./modules/mainThread";
import _channel from "./modules/channel";
import menuData from "./menu.json";
import exeMenuData from "./exeMenu.json";

Vue.use(Vuex);
const baseInfo = localStorage.getItem("baseInfo")
  ? JSON.parse(localStorage.getItem("baseInfo"))
  : null;

export default new Vuex.Store({
  modules: {
    _interface,
    _protocol,
    _datasource,
    _role,
    _mainThread,
    _channel,
  },
  state: {
    themeClass: localStorage.getItem("themeClass"),
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
    globalLoading: false,
    baseInfo: baseInfo,
    menuData: baseInfo && baseInfo.Type == 1 ? menuData : exeMenuData,
    authIds: localStorage.getItem("authIds")
      ? JSON.parse(localStorage.getItem("authIds"))
      : [],
    // 页面
    breadcrumb: [],
    // 
    city: localStorage.getItem("city")
      ? JSON.parse(localStorage.getItem("city"))
      : null,
    time: localStorage.getItem("time")
      ? JSON.parse(localStorage.getItem("time"))
      : null,
    currentDetail: null,
    isCollapse: localStorage.getItem("isCollapse")
      ? JSON.parse(localStorage.getItem("isCollapse"))
      : false,
  },
  getters: {},
  mutations: {
    // 设置主题
    setTheme(state, payload) {
      localStorage.setItem("themeClass", payload);
      state.themeClass = payload;
    },
    // 切换语言
    changeLanguage(state, payload) {
      localStorage.setItem("lanague", payload); // 这样页面刷新就不会改变选择的语言了
      this.$i18n.locale = payload;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
      localStorage.setItem("userInfo", JSON.stringify(data));
    },
    setGlobalLoading(state, data) {
      state.globalLoading = data;
    },
    setAuthIds(state, data) {
      state.authIds = data;
      localStorage.setItem("authIds", JSON.stringify(data));
    },
    setBreadcrumb(state, data) {
      state.breadcrumb = data
    },
    setCity(state, data) {
      localStorage.setItem("city", JSON.stringify(data));
      state.city = data
    },
    setTime(state, data) {
      localStorage.setItem("time", JSON.stringify(data));
      state.time = data
    },
    setIsCollapse(state, data) {
      localStorage.setItem("isCollapse", data);
      state.isCollapse = data
    },

  },
  actions: {},
});
