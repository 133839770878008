// import Vue from 'vue'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// import "./utils/rem";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "./components/global/index";
import "./styles/index.scss";
import "vue2-animate/src/sass/vue2-animate.scss";
import "animate.css";
import console from "@/utils/dateLog";
import utils from "@/utils/index";
import fontSize from "./utils/fontSize";
import "./directive/index";
import i18n from "@/i18n";

// 设置默认主题
store.commit("setTheme", "theme-default");

moment.locale("zh-cn");
// Vue.use(ElementUI);

Vue.prototype.$Moment = moment;
Vue.prototype.$FontSize = fontSize;
Vue.prototype.$utils = utils;
Vue.prototype.$baseUrl =
  process.env.NODE_ENV === "development" ? "proxy" : window.g.baseUrl;
Vue.prototype.$isDevelopment = process.env.NODE_ENV === "development";
// Vue.prototype.$isDevelopment = false;

Vue.config.productionTip = false;

(async function () {
  try {
  } catch (error) {
    console.error("🚀 ~ file: main.js:41 ~ error:", error);
  } finally {
    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  }
})();
