export default {
  state: () => ({
    // 通信协议类型,1=modbus,2=can,3=opc,4=预定义,5=自定义
    datasource_type_list: [
      {
        label: "Sqlite",
        value: 0,
      },
      {
        label: "Access",
        value: 1,
      },
      {
        label: "Sqlserver",
        value: 2,
      },
      {
        label: "Mysql",
        value: 3,
      },
    ],
    datasource_type_map: {
      0: "Sqlite",
      1: "Access",
      2: "Sqlserver",
      3: "Mysql",
    },
  }),
};
