<template>
  <el-form class="global-form-com" ref="form" :model="form" v-bind="formConfig">
    <el-row
      class="row-box"
      v-for="(row, index) in formData"
      :key="index"
      v-bind="row.rowProps"
    >
      <el-row v-bind="row.rowItemProps" class="row-content">
        <el-col v-for="col in row.cols" :key="col.key" v-bind="col.colProps">
          <el-form-item v-if="!col.hidden" v-bind="col.formItemProps">
            <!-- <slot v-if="col.type === 'slot'" :name="col.key" :row="row"></slot> -->
            <component
              v-if="col.type === 'component'"
              :is="col.componentName"
              v-bind="col"
              :col="col"
              :token="token"
              v-on="col.itemEvents || {}"
              :form="form"
              @onValidateField="onValidateField"
            ></component>
            <el-select
              v-else-if="col.type === 'el-select-tree'"
              :ref="`select_${col.key}`"
              popper-class="custom-select-tree"
              :style="{ width: selectOptionWidth }"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
              @focus="setOptionWidth"
            >
              <el-option :value="form[col.key]" :label="col.treeLabel">
                <el-tree
                  :ref="`select_tree_${col.key}`"
                  v-bind="col.treeProps"
                  @node-click="handleNodeClick($event, col)"
                >
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <GlobalAutoTooltip
                      :content="node.label"
                      placement="right"
                    />
                  </span>
                </el-tree>
              </el-option>
            </el-select>
            <el-select
              v-else-if="col.type === 'el-select'"
              :style="{ width: selectOptionWidth }"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
              @focus="setOptionWidth"
            >
              <el-option
                :label="option[col.labelKey || 'label']"
                :value="option[col.valueKey || 'value']"
                v-for="option in col.options"
                :key="option[col.valueKey || 'value']"
              ></el-option>
            </el-select>
            <template v-else-if="col.type === 'el-select-button'">
              <el-select
                :style="{ width: selectOptionWidth }"
                v-model="form[col.key]"
                v-bind="col.itemProps || {}"
                v-on="col.itemEvents || {}"
                @focus="setOptionWidth"
              >
                <el-option
                  :label="option[col.labelKey || 'label']"
                  :value="option[col.valueKey || 'value']"
                  v-for="option in col.options"
                  :key="option[col.valueKey || 'value']"
                ></el-option>
              </el-select>
              <template v-if="col.buttons">
                <el-button
                  v-for="btn in col.buttons"
                  :key="btn.text"
                  class="select-button"
                  :type="btn.type || 'primary'"
                  :icon="btn.icon"
                  @click="btn.click(form[col.key])"
                  >{{ btn.text }}</el-button
                >
              </template>
            </template>
            <el-checkbox
              v-else-if="col.type === 'el-checkbox'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
              >{{ col.name }}</el-checkbox
            >
            <el-radio-group
              v-else-if="col.type === 'el-radio-group'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            >
              <el-radio
                v-for="option in col.options"
                :key="option[col.valueKey || 'value']"
                :label="option[col.labelKey || 'value']"
                >{{ option[col.labelKey || "label"] }}</el-radio
              >
            </el-radio-group>
            <el-input-number
              v-else-if="col.type === 'el-input-number'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            ></el-input-number>
            <el-transfer
              v-else-if="col.type === 'el-transfer'"
              v-model="form[col.key]"
              :data="col.options"
            ></el-transfer>
            <GlobalTable
              v-else-if="col.type === 'el-table'"
              :tableColumn="col.column"
              :tableData="form.col.key"
              :isSelection="true"
              @onSelectionChange="col.selectionChange"
            >
            </GlobalTable>
            <el-upload
              class="custom-upload"
              :ref="`upload_${col.key}`"
              v-else-if="col.type === 'el-upload'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
              :on-exceed="(file, fileList) => handleExceed(file, fileList, col)"
              :action="$baseUrl + '/api/common/upload?token=' + token"
              :on-error="($event) => handleError($event, col)"
              :on-success="
                ($event, file, fileList) =>
                  handleSuccess($event, file, fileList, col)
              "
            >
              <el-button
                v-if="col.showUploadBtn"
                v-bind="col.uploadBtnProps || { size: 'mini', type: 'primary' }"
                slot="trigger"
                >上传</el-button
              >
              <!-- <img
                v-if="col.showImage && form[col.key]"
                :src="$baseUrl + form[col.key]"
                class="avatar"
              /> -->
              <div class="file-list-wrap">
                <span
                  class="file-list-item"
                  v-for="(item, index) in col.fileList"
                  :key="item.url"
                >
                  <el-image
                    v-if="col.showImage"
                    :src="item.url"
                    class="upload-image-item"
                  >
                    <!-- 上传图片预览 -->
                    <!-- :preview-src-list="col.fileList.map((item) => item.url)" -->
                  </el-image>
                  <div class="upload-file-item" v-else-if="col.showFiles">
                    <i
                      :class="[
                        col.fileIconClass || 'el-icon-document',
                        'upload-icon-item',
                      ]"
                    ></i>
                    <GlobalAutoTooltip :content="item.name" placement="right" />
                  </div>
                  <span v-else class="flex-1 w0 ml-10" style="text-align: left"
                    ><GlobalAutoTooltip
                      :content="col.fileList"
                      placement="right"
                  /></span>
                  <i
                    class="el-icon-delete icon-pointer"
                    @click="deleteUrl(index, col)"
                  ></i>
                </span>
              </div>

              <!-- <div slot="tip"
                 class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
            <el-input
              v-else-if="col.type === 'el-input'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            >
              <i
                v-if="col.slot"
                :slot="col.slot.name"
                :class="['icon-pointer', col.slot.class]"
                @click="col.slot.click(col)"
              ></i>
            </el-input>
            <el-input
              v-else-if="col.type === 'el-textarea'"
              type="textarea"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            ></el-input>
            <el-autocomplete
              v-else-if="col.type === 'el-autocomplete'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            ></el-autocomplete>
            <el-cascader
              v-else-if="col.type === 'el-cascader'"
              v-model="form[col.key]"
              :options="col.options"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
              clearable
            ></el-cascader>

            <el-image
              v-else-if="col.type === 'el-image'"
              :src="$baseUrl + form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            ></el-image>
            <el-date-picker
              v-else-if="col.type === 'el-date-picker'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            >
            </el-date-picker>
            <div v-else-if="col.type === 'text'">{{ form[col.key] }}</div>
            <a
              class="download-btn"
              v-else-if="col.type === 'download'"
              :download="form[col.key]"
              :href="form[col.key]"
              >下载</a
            >
            <template v-else-if="col.type === 'el-button'">
              <el-button
                v-for="btn in col.btns"
                v-bind="btn.itemProps || {}"
                v-on="btn.itemEvents || {}"
                :key="btn.name"
                >{{ btn.name }}</el-button
              >
            </template>

            <div class="unit-box" v-if="col.unit" :name="col.key">
              {{ col.unit }}
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-row>
    <slot name="footer"></slot>
  </el-form>
</template>

<script>
import extendComponents from "../extend/index";

export default {
  components: {
    ...extendComponents,
  },
  // mixins: [extend],
  props: {
    formConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    formData: {
      type: Array,
      default() {
        return [];
      },
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectOptionWidth: "auto",
      token: JSON.parse(localStorage.getItem("userInfo")).token,
    };
  },
  created() {
    // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      this.token = userInfo.token;
    }
  },
  methods: {
    setOptionWidth(event) {
      // 下拉框弹出时，设置弹框的宽度
      this.$nextTick(() => {
        this.selectOptionWidth = event.srcElement.offsetWidth + "px";
      });
    },
    handleNodeClick(data, col) {
      this.$set(this.form, [col.key], data.id);
      col.treeLabel = data[col.treeProps.props.label];
      this.$refs[`select_${col.key}`][0].blur();
    },
    handleSuccess(res, file, fileList, col) {
      if (res.code == 1) {
        //push 上传图片
        col.fileList = fileList;
        this.$set(
          this.form,
          [col.key],
          col.fileList.map((item) => {
            item.url = this.$baseUrl + item.response.data.url;
            return item.url;
          })
        );
      } else {
        const index = fileList.findIndex((item) => item.uid == file.uid);
        fileList.splice(index, 1);
      }
      // this.$refs.`upload`[0] && this.$refs.`upload`[0].clearFiles();
      this.$message({
        message: res.msg,
        type: res.code == 1 ? "success" : "error",
        duration: 1500,
      });
    },
    onValidateField(fieldKey) {
      console.log(
        "🚀 ~ file: CustomForm.vue:348 ~ onValidateField ~ fieldKey:",
        fieldKey
      );
      this.$refs.form.validateField(fieldKey); // 手动触发fileList校验规则
    },
    handleError(err, col) {
      console.log(
        "🚀 ~ file: CustomForm.vue ~ line 179 ~ handleError ~ err, col",
        err,
        col
      );
      const that = this;
      const error = JSON.parse(err.message);
      this.$refs.upload[0] && this.$refs.upload[0].clearFiles();
      that.$message({
        message: error.msg,
        type: "error",
        duration: 1500,
        // onClose: function() {
        //   that.$router.push('/login')
        // }
      });
    },
    deleteUrl(index, col) {
      col.fileList.splice(index, 1);
      this.$set(this.form, [col.key], [...col.fileList]);
    },
    handleExceed(files, fileList, col) {
      this.$message.warning(
        `当前限制选择 ${col.itemProps.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
  },
};
</script>

<style lang="scss">
$baseHeight: 40;
.global-form-com {
  padding-bottom: 10px;
  box-sizing: border-box;
  .el-row--flex {
    flex-wrap: wrap;
  }

  .row-box {
    &:first-child {
      border-top: none;
    }
  }

  .custom-datetime {
    position: absolute !important;
    left: 0 !important;
    top: px2vh(40) !important;
  }

  .custom-upload {
    display: flex;
  }

  .file-list-wrap {
    margin-left: 10px;
    @include flex(row, flex-start, center);
    flex-wrap: wrap;

    .file-list-item {
      @include flex(row, center, flex-start);
      margin: 0 10px;
      // border: 1px solid #dfeaf2;
      height: 60px;
    }

    .upload-image-item {
      width: 60px;
      height: 60px;

      img {
        object-fit: cover;
      }
    }

    .el-icon-delete {
      color: red;
    }

    .upload-file-item {
      width: 50px;
      height: 20px;
      text-align: center;
      // @include flex(column, space-between, center);

      .upload-icon-item {
        font-size: 20px;
      }

      .el-tooltip {
        width: 100%;
        line-height: 1.2;
      }
    }
  }

  .el-form-item {
    // margin: px2vh(10) 40px 20px 10px;
    margin: px2vh(15);

    // background-color: rgba(33, 166, 154, 0.1);
    padding: 0;
    box-sizing: border-box;
    border-radius: px2vh(4);
  }
  .el-form-item__label {
    // @include font4vh(#505887, 14, 400);
    font-size: 14px;
    color: #505887;
    font-weight: 400;
    height: px2vh($baseHeight);
    font-size: 12px;
    line-height: px2vh($baseHeight);
    padding-right: px2vh(10);
  }

  .el-form-item__content {
    line-height: px2vh($baseHeight);
    display: flex;
  }

  .el-radio-group {
    .el-radio {
      line-height: px2vh($baseHeight);
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    // background-color: transparent;
    border-color: rgba(203, 224, 255, 0.5);
  }

  .el-form-item__content {
    .el-input__inner {
      border-radius: px2vh(6);
      // border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-color: #dfeaf2;
      background-size: 100% 100%;
      // @include font4vh(#000, 14, 400);
      font-size: 12px;
      color: #000;
      font-weight: 400;
      background-color: transparent;
      height: px2vh($baseHeight);
      line-height: px2vh($baseHeight);

      &::placeholder {
        color: #bbbbbb;
      }
    }

    .el-cascader {
      width: 100%;
      line-height: px2vh($baseHeight);
    }

    .el-autocomplete {
      width: 100%;
    }

    .el-textarea {
      // @include font4vh(#000, 14, 400);
      font-size: 14px;
      color: #000;
      font-weight: 400;
    }
    .el-textarea__inner {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      &::placeholder {
        // @include font4vh(#bbbbbb, 14, 400);
        font-size: 14px;
        color: #bbb;
        font-weight: 400;
      }
    }

    // .el-select .el-tag {
    //   padding: 0 px2vh(8);
    //   margin: px2vh(4);
    //   height: px2vh(28);
    //   line-height: px2vh(28);
    // }

    .el-input__icon {
      line-height: px2vh($baseHeight);
    }

    .el-date-editor.el-input,
    .el-date-editor--daterange.el-input__inner {
      height: px2vh($baseHeight);
      width: 100%;
    }

    .el-select {
      width: 100% !important;
    }
    .el-date-editor--daterange.el-input__inner {
      padding: 0;
      border: none;
      @include font4vh(#fff, 14, 400);
    }

    .el-date-editor .el-range-separator {
      @include font4vh(#fff, 14, 400);
      line-height: px2vh($baseHeight);
    }

    .el-range-input {
      background-color: transparent;
      @include font4vh(#fff, 14, 400);
    }

    // .el-select__input {
    //   @include font4vh(#fff, 14, 400);
    // }

    .el-textarea__inner {
      @include font4vh(#000, 14, 400);
      background-color: transparent;
      border-color: rgba(203, 224, 255, 0.5);
      &::-webkit-scrollbar {
        display: none !important; /* Chrome Safari */
      }
    }

    .el-input__count {
      background: transparent;
      bottom: 0;
      @include font4vh(rgba(168, 214, 255, 0.5), 14, 400);
    }
  }

  .select-button {
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 10px;
    padding: 10px 20px;
    font-size: 14px;
  }

  .has-unit {
    .el-input__inner {
      padding-right: px2vh(30);
    }
  }

  .has-unit2 {
    .el-input__inner {
      padding-right: px2vh(40);
    }
  }

  .download-btn {
    color: rgb(1, 194, 255);
    cursor: pointer;
  }

  .el-upload {
    @include flex(row, flex-start, center);
  }

  .custom-upload {
    width: 100%;
    // height: px2vh($baseHeight);
    // display: flex;
    // align-items: center;
    .el-upload {
      height: px2vh($baseHeight);
    }
  }

  .el-upload-list {
    display: none;
  }
  .el-transfer,
  .el-transfer__buttons {
    @include flex(row);
    .el-transfer__buttons {
      height: 50px;
      .el-transfer__button {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 20px;
      }
    }
  }

  .avatar {
    margin-left: px2vh(10);
    height: px2vh(40);
  }

  .unit-box {
    position: absolute;
    right: px2vh(10);
    top: 0;
    // @include font4vh(rgba(203, 224, 255, 1), 14, 400);
    font-size: 14px;
    font-weight: 400;
    color: rgba(203, 224, 255, 1);
  }
}
</style>
