const nodeColorMap = {
  defaultNode: {
    stroke: "#F9F871",
    fill: "#FBFBD6",
  },
  activeNode: {
    stroke: "#5F95FF",
    fill: "#EFF4FF",
  },
  debugNode: {
    stroke: "#FFA500",
    fill: "#FFDAB3",
  },
  errorNode: {
    stroke: "#FF0000",
    fill: "#FFCCCC",
  },
  successNode: {
    stroke: "#00FF00",
    fill: "#CCFFCC",
  },
  boundaryNormalNode: {
    stroke: "#00FF00",
    fill: "#CCFFCC",
  },
  boundaryDebugNode: {
    stroke: "#FF0000",
    fill: "#FFCCCC",
  },
};
let currentId = 0;

const _updateTableItem = (data, tableData, status) => {
  const index = data[0] ? +data[0].test_index : null;

  if (index) {
    tableData[index - 1].testStatus = status; // 1为测试中， 2为测试成功，3为测试失败
  }
};

// 调试模式为当前节点增加包围盒
const updateNodeBoundary = (id, nodes, graph) => {
  if (currentId == id) return;

  nodes.forEach((node) => {
    if (node.hasTool("boundary")) {
      node.removeTool("boundary");
      return;
    }
    if (node.id == id) {
      currentId = id;
      const { x, y } = node.position();
      const { width, height } = node.size();

      // 将视口中心移动到节点中心
      graph.centerPoint(x + width / 2, y + height / 2);

      // 增加边界
      node.addTools({
        name: "boundary",
        args: {
          attrs: {
            fill: node.data.form.is_debug_point
              ? nodeColorMap.boundaryDebugNode.fill
              : nodeColorMap.boundaryNormalNode.fill,
            stroke: node.data.form.is_debug_point
              ? nodeColorMap.boundaryDebugNode.stroke
              : nodeColorMap.boundaryNormalNode.stroke,
            strokeWidth: 1,
            fillOpacity: 0.2,
          },
        },
      });
    }
  });
};

export default {
  state: () => ({
    // //===============================事件
    // //运行错误事件
    // public const int EVENT_ERROR=0;
    // //自检事件
    // public const int EVENT_CHECK_OK = 200;
    // public const int EVENT_CHECK_CONNECTFAIL = 290;
    // //缺少数据事件
    // public const int EVENT_NODATA_DEVICE = 100;
    // public const int EVENT_NODATA_DEVICE_INTERFACE = 101;
    // public const int EVENT_NODATA_PROTOCOL = 102;
    // public const int EVENT_NODATA_PROTOCOL_ORDER = 103;
    // public const int EVENT_NODATA_FLOWNODE = 104;
    // public const int EVENT_NODATA_FLOWNODE_ACTION = 105;
    // public const int EVENT_NODATA_FLOWNODE_SET = 106;
    // //执行下一步事件
    // public const int EVENT_NEXT_FLOWNODE_SET = 107;
    // //缺少开始节点数据
    // public const int EVENT_NODATA_FLOWNODE_START = 108;
    // //缺少终止节点数据
    // public const int EVENT_NODATA_FLOWNODE_END = 109;
    // //节点连接数据异常
    // public const int EVENT_NODATA_FLOWNODE_CONNECT = 110;
    //    //读取到数据
    //    public const int EVENT_RUN_READ_DATA = 300;
    //    //控制指令发送
    //    public const int EVENT_RUN_CONTROL = 301;
    //    //等待
    //    public const int EVENT_RUN_WAIT = 303;
    //    //跳转
    //    public const int EVENT_RUN_JUMP = 304;

    // //==============================状态
    // //自检状态
    // public const int STATUS_CHECK_READY = 10000;
    // public const int STATUS_CHECK_START = 10001;
    // // 自检下一个流程数据
    // public const int STATUS_CHECK_NEXT = 10002;
    // public const int STATUS_CHECK_FINISH = 10003;
    // //流程数据监测OK
    // public const int STATUS_CHECK_DATAOK = 10004;
    // //自检通过
    // public const int STATUS_CHECK_RESULT_PASS = 10005;
    // //自检未通过
    // public const int STATUS_CHECK_RESULT_NOPASS = 10006;
    // //自检下一个设备
    // public const int STATUS_CHECK_NEXT_DEVICE = 10007;
    // //运行状态
    // public const int STATUS_RUN_READY = 20000;
    // public const int STATUS_RUN_START = 20001;
    // public const int STATUS_RUN_NEXT = 20002;
    // public const int STATUS_RUN_FINISH = 20003;
    // public const int STATUS_RUN_ERROR = 20004;
    // public const int STATUS_RUN_DEBUGGER = 20005;
    // //连接成功
    // public const int STATUS_CONNECT_SUCCESS = 30001;
    // //连接失败
    // public const int STATUS_CONNECT_FAIL = 30002;
    // //连接断开
    // public const int STATUS_CONNECT_DISCONNECT = 30003;

    // //==============================日志
    // //运行日志
    // public const int LOG_NORMAL = 40001;
    // //消息日志
    // public const int LOG_INFO = 40002;
    // //错误日志
    // public const int LOG_ERROR = 40003;

    // 添加资源成功后异步通知
    // public const int LOG_ERROR = 501;
    eventCodeMap: {
      0: {
        label: "运行错误",
        color: "red",
      },
      200: {
        label: "自检成功",
        color: "green",
        writeLog: (data) => {
          return data[0].device.name;
        },
        updateStatus: (data, devices) => {
          let device = devices.find(
            (item) => item.device.id == data[0].device.id
          );

          if (device) {
            device.checkStatus = 2;
          }
        },
      },
      290: {
        label: "自检连接失败",
        color: "green",
        writeLog: (data) => {
          return data[0].device.name;
        },
        updateStatus: (data, devices) => {
          let device = devices.find(
            (item) => item.device.id == data[0].device.id
          );

          if (device) {
            device.checkStatus = 3;
          }
        },
      },
      100: {
        label: "缺少仪器数据",
        color: "red",
      },
      101: {
        label: "缺少仪器接口",
        color: "red",
      },
      102: {
        label: "缺少仪器协议",
        color: "red",
      },
      103: {
        label: "缺少仪器协议指令",
        color: "red",
      },
      104: {
        label: "缺少流程节点",
        color: "red",
      },
      105: {
        label: "缺少流程节点动作",
        color: "red",
      },
      106: {
        label: "缺少流程测试集",
        color: "red",
      },
      107: {
        label: "执行下一步事件",
        color: "green",
      },
      108: {
        label: "缺少开始节点",
        color: "red",
      },
      109: {
        label: "缺少终止节点",
        color: "red",
      },
      110: {
        label: "节点连接数据异常",
        color: "red",
      },
      300: {
        label: "读取到数据",
        color: "green",
        writeLog: (data) => {
          return data[1] || "";
        },
        updateStatus: (data, nodes, graph) => {
          updateNodeBoundary(data[0].id, nodes, graph);
        },
      },
      301: {
        label: "控制指令发送",
        color: "green",
        writeLog: (data) => {
          return data[1] || "";
        },
        updateStatus: (data, nodes, graph) => {
          updateNodeBoundary(data[0].id, nodes, graph);
        },
      },
      303: {
        label: "等待",
        color: "green",
      },
      304: {
        label: "跳转",
        color: "green",
      },
      305: {
        label: "读取到空数据",
        color: "green",
      },
      306: {
        label: "提示",
        color: "green",
      },
      307: {
        label: "展示",
        color: "green",
        showRealData(data, configRef) {
          // 展示回推的实时数据
          const show_label_id = data[0]
            ? data[0].flowAction.show_label_id
            : null;
          const result = data[0] ? data[0].result : null;

          if (show_label_id && configRef) {
            const layoutItem = configRef.layout.find(
              (item) => item.id == show_label_id
            );

            if (layoutItem) {
              layoutItem.data.value = result;
            }
          }
        },
      },
      10000: {
        label: "自检就绪",
        color: "green",
      },
      10001: {
        label: "自检开始",
        color: "green",
      },
      10002: {
        label: "自检下一步",
        color: "green",
        writeLog: (data) => {
          return data[0].name;
        },
        checkStatus: 1,
      },
      10003: {
        label: "自检完成",
        color: "green",
      },
      10004: {
        label: "流程数据监测OK",
        color: "green",
      },
      10005: {
        label: "自检通过",
        color: "green",
      },
      10006: {
        label: "自检未通过",
        color: "green",
        updateTableItem(data, tableData) {
          _updateTableItem(data, tableData, 3);
        },
      },
      10007: {
        label: "自检下一个设备",
        color: "green",
        writeLog: (data) => {
          return data[0].device.name;
        },
      },
      20000: {
        label: "运行就绪",
        color: "green",
      },
      20001: {
        label: "运行开始",
        color: "green",
        updateTableItem(data, tableData) {
          _updateTableItem(data, tableData, 1);
        },
        updateStatus: (data, nodes, graph) => {
          updateNodeBoundary(data[0].id, nodes, graph);
        },
      },
      20002: {
        label: "运行下一步",
        color: "green",
        writeLog: (data) => {
          console.log("🚀 ~ file: mainThread.js:302 ~ data:", data);
          return data[0].name;
        },
        updateStatus: (data, nodes, graph) => {
          updateNodeBoundary(data[0].id, nodes, graph);
        },
      },
      20003: {
        label: "运行完成",
        color: "green",
        updateTableItem(data, tableData) {
          _updateTableItem(data, tableData, 2);
        },
        writeLog: (data) => {
          return data[0].name;
        },
        updateStatus: (data, nodes, graph) => {
          updateNodeBoundary(data[0].id, nodes, graph);
        },
      },
      20004: {
        label: "运行错误",
        color: "green",
        updateTableItem(data, tableData) {
          _updateTableItem(data, tableData, 3);
        },
      },
      20005: {
        label: "运行调试",
        color: "green",
      },
      30001: {
        label: "连接成功",
        color: "green",
        writeLog: (data) => {
          return data[0].name;
        },
        updateStatus: (data, nodes, graph) => {
          updateNodeBoundary(data[0].id, nodes, graph);
        },
      },
      30002: {
        label: "连接失败",
        color: "red",
        updateTableItem(data, tableData) {
          _updateTableItem(data, tableData, 3);
        },
        updateStatus: (data, nodes, graph) => {
          updateNodeBoundary(data[0].id, nodes, graph);
        },
      },
      30003: {
        label: "连接断开",
        color: "red",
        updateTableItem(data, tableData) {
          _updateTableItem(data, tableData, 3);
        },
      },
      40001: {
        label: "运行日志",
        color: "green",
        writeLog: (data) => {
          return data[1] || "";
        },
        updateStatus: (data, nodes, graph) => {
          updateNodeBoundary(data[0].id, nodes, graph);
        },
      },
      40002: {
        label: "消息日志",
        color: "green",
        writeLog: (data) => {
          return data[1] || "";
        },
        updateStatus: (data, nodes, graph) => {
          updateNodeBoundary(data[0].id, nodes, graph);
        },
      },
      40003: {
        label: "错误日志",
        color: "red",
      },
      50001: {
        label: "资源添加成功",
        color: "green",
        hasCallback: true,
      },
    },
    nodeColorMap: nodeColorMap,
    checkStatusMap: {
      0: {
        name: "未检测",
        color: "#999",
      },
      1: {
        name: "检测中",
        color: "#ff9900",
      },
      2: {
        name: "检测完成",
        color: "#67c23a",
      },
      3: {
        name: "检测失败",
        color: "#f56c6c",
      },
    },
    // 结果类型 0=字符串，1=数值，2=数组
    result_type_list: [
      {
        label: "字符串",
        value: 0,
      },
      {
        label: "数值",
        value: 1,
      },
      {
        label: "数组",
        value: 2,
      },
    ],
    result_type_map: {
      0: "字符串",
      1: "数值",
      2: "数组",
    },
  }),
};
