import { Message } from "element-ui";
export default function (moduleName, method, ...args) {
  if (window._isAppEnv) {
    const result = JSON.parse(window[moduleName][method](...args));
    console.log(
      "🚀 ~ file: index.js:5 ~ moduleName:",
      moduleName,
      "--method:",
      method,
      "--args:",
      args
    );
    console.log("🚀 ~ file: index.js:4 ~ result:", result);

    if (result && result.code != 1) {
      // 1 is success
      Message({
        message: result.mes,
        type: "error",
      });
    }
    return result;
  }

  return null;
}
