<template>
  <div class="main-layout-box">
    <div class="main-layout-left">
      <Menu />
    </div>
    <div class="main-layout-right">
      <Header />
      <transition
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Menu from "./components/Menu.vue";
import Header from "./components/Header.vue";
export default {
  components: {
    Menu,
    Header,
  },
};
</script>

<style lang="scss">
.main-layout-box {
  height: 100%;
  width: 100%;
  display: flex;

  .main-layout-left {
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgb(182, 233, 255) -3%,
      rgba(240, 243, 246, 0.8) 100%
    );
    // background: linear-gradient(
    //   180deg,
    //   rgba(216, 232, 246, 0.8) -3%,
    //   rgba(240, 243, 246, 0.8) 100%
    // );
    // box-shadow: 0 1px 4px rgba(0,21,41,.08);
  }
  .main-layout-right {
    height: 100%;
    width: 0;
    flex: 1;
    // background: linear-gradient(180deg, #d8e8f6 -3%, #f0f3f6 100%);
    background: #f0f2f5;
    display: flex;
    flex-direction: column;
  }
}
</style>
