<template>
  <el-table
    class="test-value-table"
    :data="form[componentKey]"
    style="width: 100%"
  >
    <el-table-column type="index" label="序号"> </el-table-column>
    <el-table-column prop="device_name" label="测试设备" show-overflow-tooltip>
    </el-table-column>
    <el-table-column prop="label_name" label="测试指标" show-overflow-tooltip>
    </el-table-column>
    <el-table-column prop="value" label="测试值" show-overflow-tooltip>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    componentKey: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.test-value-table {
  .cell {
    font-size: 14px;
  }
}
</style>
