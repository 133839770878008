<template>
  <div class="login-box">
    <!-- <div class="login-left-big-box">
      <div class="left-top-box">
        <div class="title-box">垃圾分类系统</div>
        <div class="title-ch-box">
        </div>
        <div class="title-en-box">
        </div>
      </div>
      <div class="left-bottom-box">
        <img
          class="left-login-img"
          src="@/assets/login/login_left.png"
          alt=""
          v-animate="{
            enterClass: 'animate__fadeIn',
            leaveClass: 'animate__fadeOut',
            enterDelay: '100ms',
            leaveDelay: '100ms',
          }"
        />
      </div>
    </div> -->
    <div class="login-right-big-box">
      <el-form
        ref="form"
        :model="form"
        :rules="ruleForm"
        status-icon
        class="login-form"
        v-animate="{
          enterClass: 'animate__bounceInRight',
          leaveClass: 'animate__bounceOutRight',
          enterDelay: '100ms',
          leaveDelay: '100ms',
        }"
      >
        <!-- <div class="login-form-title">登录</div> -->
        <div class="login-form-title">
          <img src="../../assets/form_title.png" alt="" />
        </div>
        <!-- <div class="login-form-introduce">输入您的账号密码以访问您的帐户</div> -->
        <!-- <div class="login-form-create-account">
          <span>没有账号吗 ?</span> <el-link href="/#/register">创建账号</el-link>
        </div> -->
        <el-form-item label="" prop="account">
          <el-input
            type="text"
            clearable
            prefix-icon="custom-icon-user"
            v-model="form.account"
            auto-complete="off"
            placeholder="账号"
            placeholder-style="color:#fff;"
          >
            <template #prefix>
              <img
                src="../../assets/icon_user.png"
                class="prefix-image"
                alt="账号"
              />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            type="password"
            clearable
            prefix-icon="custom-icon-password"
            v-model="form.password"
            auto-complete="off"
            placeholder=""
            placeholder-style="color:#fff;"
          >
            <template #prefix>
              <img
                src="../../assets/icon_pas.png"
                class="prefix-image"
                alt="密码"
              /> </template
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="remember-container">
            <el-checkbox
              class="remember"
              v-model="remember"
              @change="rememberChange"
              >记住密码</el-checkbox
            >
            <!-- <el-popover
              placement="top"
              width="220"
              popper-class="workface-popper"
            >
              <p>{{ loginInfo ? loginInfo.pwd_hint : "请联系管理员" }}</p>
              <p>
                <span>管理员：</span>
                <span class="remember-phone">12333333333</span>
              </p>
              <div slot="reference" class="backend-btn">忘记密码</div>
            </el-popover> -->

            <!-- <a class="backend-btn" href="javascript:;">后台管理</a> -->
          </div>
        </el-form-item>
        <el-form-item class="submit-box">
          <el-button
            v-waves="{ className: 'waves-light' }"
            class="login-btn"
            type="primary"
            @click="submit"
            :loading="logining"
            >立即登录</el-button
          >
        </el-form-item>
        <!-- <div class="login-form-forgot-password">
          <span>忘记密码了?</span>
          <span class="reset-password-box">重置密码</span>
        </div> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/user";

export default {
  components: {},
  data() {
    return {
      form: {
        account: "",
        password: "",
      },
      ruleForm: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      remember: false,
      logining: false,
      loginInfo: null,
    };
  },
  created() {
    document.addEventListener("keydown", this.onKeydown);
    this.loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
    if (process.env.NODE_ENV === "development") {
      this.form.account = "admin";
      this.form.password = "123456";
    }
    this.init();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    init() {
      this.remember =
        localStorage.getItem("isRemember") === "true" ? true : false;

      if (this.remember && localStorage.getItem("rememberForm")) {
        this.form = JSON.parse(localStorage.getItem("rememberForm"));
      }
    },
    onKeydown(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    rememberChange(val) {
      localStorage.setItem("isRemember", val);
    },
    submit(event) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.logining = true;
          login(this.form)
            .then((res) => {
              console.log("🚀 ~ login ~ res:", res);
              this.remember
                ? localStorage.setItem(
                    "rememberForm",
                    JSON.stringify(this.form)
                  )
                : localStorage.removeItem("rememberForm");
              this.$store.commit("setUserInfo", res.data.userinfo);
              localStorage.setItem("message", 1);
              const _this = this;
              this.$message({
                message: "登录成功",
                type: "success",
                duration: 1000,
                onClose() {
                  _this.$router.push("/main-layout/home");
                  _this.logining = false;
                },
              });
            })
            .catch((err) => {
              this.logining = false;
            });
          console.log(this.form, "----------------------打印");
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.workface-popper {
  p {
    padding: 0;
    margin: 0;
  }
}
.login-box {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../assets/bg.png") no-repeat center;
  background-size: 100% 100%;
  @include flex(row, center, center);

  // .login-left-big-box {
  //   width: 50%;
  //   height: 100%;
  //   // background: linear-gradient(180deg, #394f75 -3%, #051737 100%);
  //   background: rgb(182, 233, 255);
  //   position: relative;
  //   .left-top-box {
  //     padding: 70px 80px 40px 60px;
  //     box-sizing: border-box;
  //     position: relative;
  //     z-index: 2;

  //     .title-box {
  //       font-size: 24px;
  //       font-weight: 350;
  //       color: #ffffff;
  //       margin-bottom: 20px;
  //     }
  //     .title-ch-box {
  //       font-size: 14px;
  //       font-weight: 350;
  //       line-height: 25px;
  //       color: rgba(255, 255, 255, 0.8);
  //       margin-bottom: 10px;
  //     }
  //     .title-en-box {
  //       font-size: 14px;
  //       font-weight: 350;
  //       line-height: 25px;
  //       letter-spacing: 0px;
  //       color: rgba(255, 255, 255, 0.4);
  //     }
  //   }

  //   .left-bottom-box {
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //   }
  //   .left-login-img {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //   }

  //   img {
  //     width: 100%;
  //   }
  // }
  .login-right-big-box {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-form {
      margin-top: 140px;
      // position: absolute;
      // right: 228px;
      width: 360px;
      background: #fff;
      background: url("../../assets/bg_form.png") no-repeat center;
      background-size: 100% 100%;
      padding: 70px 60px;
      // padding: var(--clkd-padding-default);
      // border: var(--clkd-border-default);

      .el-input__inner {
        height: 60px;
      }

      .prefix-image {
        width: 20px; /* 根据你的需要调整图片大小 */
        height: 20px; /* 根据你的需要调整图片大小 */
        margin-right: 10px; /* 与输入框内容的间距 */
        margin-top: 20px;
      }

      .el-checkbox__label {
        color: #fff;
      }
      .login-form-title {
        font-family: Source Han Sans;
        font-size: 24px;
        font-weight: 350;
        line-height: 28px;
        color: #36383d;
        margin-bottom: 20px;
      }

      .login-form-introduce {
        font-family: Source Han Sans;
        font-size: 14px;
        font-weight: 350;
        line-height: 16px;
        color: #a9b2b9;
        margin-bottom: 27px;
      }
      .login-form-create-account,
      .el-link--default {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 30px;
        @include flex(row, flex-start, center);
      }
      .el-link--default {
        color: rgba(0, 0, 0, 0.9);
        padding-bottom: 0;
        padding-left: 5px;
      }
      .remember-container {
        @include flex(row, space-between, center);
      }

      .submit-box {
        margin-top: 20px;
      }
      .login-form-forgot-password {
        font-family: Source Han Sans;
        font-size: 14px;
        font-weight: 350;
        line-height: 16.38px;
        text-align: left;
        letter-spacing: 0px;
        color: #202020;

        .reset-password-box {
          color: #416cdc;
          cursor: pointer;
        }
      }
    }
  }
}
.login-btn {
  height: 64px;
  width: 100%;
  background: #276CDB;
  border-radius: 2px;
  font-size: 24px;
  color: rgba(255, 255, 255,0.6);
}
</style>
