<template>
  <div>
    <div class="header-top-box">
      <div class="header-left-box">
        <div class="isCollapse-box" @click="onOpenClose">
          <i
            style="font-size: 20px"
            v-if="isCollapse == true"
            class="el-icon-s-fold"
          ></i>
          <i
            style="font-size: 20px"
            v-if="isCollapse == false"
            class="el-icon-s-unfold"
          ></i>
        </div>
        <span v-if="juese != 3">
          {{ city && city.name }}
        </span>
      </div>
      <div class="header-right-box">
        <a :href="$baseUrl + sysc" target="_blank">
          <img
            class="czsc-icon"
            id="topImg"
            src="@/assets/header/czsc.png"
            alt=""
        /></a>

        <div @click="get_message_reminder()">
          <!-- :is-dot="redDot" 红点 -->
          <el-badge
            :value="messageNumber"
            :max="9"
            slot="reference"
            class="badge-box"
            :hidden="messageNumber == 0"
          >
            <img
              class="msg-icon"
              id="topImg"
              src="@/assets/header/icon_news.png"
              alt=""
            />
          </el-badge>
        </div>
        <div class="right-avatar">
          <div class="avatar-box">
            <img :src="$baseUrl + avatar" alt="" />
          </div>
          <el-dropdown v-if="userInfo" trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ userInfo.nickname }}
              <!-- <i class="el-icon-setting" style="margin-left: 10px"></i> -->
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="info">信息维护</el-dropdown-item>
              <el-dropdown-item command="safe">安全管理</el-dropdown-item>
              <el-dropdown-item command="quit">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <i
          class="el-icon-setting"
          @click="goSetting"
          style="margin-left: 0px; cursor: pointer"
        ></i> -->
      </div>
    </div>
    <!-- 面包屑 -->
    <div class="breadcrumb-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item v-for="item in breadcrumb" :key="item">
          {{ item }}
        </el-breadcrumb-item> -->
        <template v-for="item in breadcrumb">
          <el-breadcrumb-item
            :key="item"
            :to="item == '工作台' ? { path: '/main-layout/home' } : ''"
          >
            {{ item }}
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { messageList } from "@/api/message.js";
import { logout } from "@/api/user.js";
export default {
  data() {
    return {
      // 角色 1垃圾，2环卫，3审核员
      juese: JSON.parse(localStorage.getItem("userInfo")).sxtype,
      // 单位id
      dw_id: JSON.parse(localStorage.getItem("userInfo")).dw_id,
      avatar: JSON.parse(localStorage.getItem("userInfo")).avatar,
      sysc: JSON.parse(localStorage.getItem("userInfo")).sysc_url,
      // 消息数量
      messageNumber: null,
      username: "用户1",
      title: "",
      status: "转到送餐模式",
      timer: null,
      // isCollapse: true,
    };
  },

  computed: {
    ...mapState(["userInfo", "breadcrumb", "city", "isCollapse"]),
    // isCollapse: {
    //   get() {
    //     return this.$store.state.isCollapse;
    //   },
    //   set(isCollapse) {
    //     this.$store.commit("setIsCollapse", !isCollapse);
    //   },
    // },
  },
  beforeDestroy() {
    this.clearTimer();
  },
  created() {
    this.init();
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        console.log("监听路由：" + JSON.stringify(to.name));

        if (to.name == "Home") {
          this.title = "普通模式";
        } else if (to.name == "User") {
          this.title = "用户管理";
        }
      },
    },
  },
  methods: {
    init() {
      // 获取消息列表
      this.getMessage();
      this.clearTimer();
      this.timer = setInterval(() => {
        this.getMessage();
      }, window.g.intervalTime);
    },
    clearTimer() {
      this.timer && clearInterval(this.timer);
      console.log("已清理定时请求");
    },
    getMessage() {
      const params = {
        dw_id: this.dw_id,
        status: 1,
      };
      messageList(params)
        .then((res) => {
          this.messageNumber = res.data.total;
        })
        .finally(() => {});
    },

    handleCommand(command) {
      if (command === "info") {
        // alert("信息维护");
        this.$router.push("/main-layout/information");
      } else if (command === "safe") {
        // alert("安全管理");
        this.$router.push("/main-layout/safe");
      } else if (command === "quit") {
        logout().then((res) => {
          this.$message.success(res.msg);

          this.$store.commit("setCity", null);
          this.$router.push("/login");
        });
      }
    },

    // goBack() {
    //   this.$router.go(-1);
    // },
    // 查看消息
    get_message_reminder() {
      this.$router.push("/main-layout/message");
      // alert("查看消息");
    },

    onOpenClose() {
      this.$store.commit("setIsCollapse", !this.isCollapse);
    },
  },
};
</script>

<style lang="scss">
.header-top-box {
  width: 100%;
  height: 64px;
  background: #fff;
  padding: 0 20px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  margin-bottom: 1px;
  @include flex(row, space-between, center);

  .header-left-box {
    @include flex(row, flex-start, center);
    font-family: Source Han Sans;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #222932;
    cursor: pointer;

    .isCollapse-box {
      margin-right: 20px;
    }
  }

  .header-right-box {
    @include flex(row, flex-end, center);

    .czsc-icon {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
  }

  .badge-box {
    cursor: pointer;
  }

  .right-avatar {
    margin: 0 20px;
    @include flex(row, center, center);
    // cursor: pointer;
    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }

    .avatar-box {
      width: 34px;
      height: 34px;
      // background: pink;
      text-align: center;
      border-radius: 50%;
      margin-right: 20px;
      overflow: hidden;
    }
  }
  .el-dropdown {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}

.breadcrumb-box {
  height: 55px;
  width: 100%;
  background: #fff;
  padding: 0 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
