import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
import cancelRequestOnRouteChange from "@/services/cancelRequestOnRouteChange";
import routes from "./routes";
import store from "../store/index";

Vue.use(VueRouter);

const baseInfo = JSON.parse(localStorage.getItem("baseInfo"));
console.log("🚀 ~ file: index.js:12 ~ baseInfo:", baseInfo);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  // routes: baseInfo && baseInfo.Type == "1" ? routes : routesExecution, // 1为开发平台，2为执行平台
  routes: routes,
});

// 路由拦截器
router.beforeEach(async function (to, from, next) {
  console.log("🚀 ~ file: index.js:18 ~ to-------:", to);
  if (window.g.openCancelRequest) cancelRequestOnRouteChange();

  if (to.meta && to.meta.breadcrumb) {
    const city = store.state.city
    const time = store.state.time
    console.log("🚀 ~ city:", city, time)
    let cityName = ""
    if (city) {
      cityName = city.name
    }
    let currentTime = ""
    if (time) {
      currentTime = time.time
    }
    console.log("🚀 ~ cityName:", cityName)
    const currentDetail = store.state.currentDetail
    const breadcrumb = ['MonthlyDetails', 'QuarterDetails'].includes(to.name) ? [...to.meta.breadcrumb, cityName, currentTime] : to.meta.breadcrumb

    store.commit('setBreadcrumb', breadcrumb)
  }

  // if (to.query.token) {
  //   localStorage.removeItem('userInfo')
  //   localStorage.removeItem('monitorAreas')
  //   localStorage.removeItem('monitorAreasCheckedIndex')
  //   localStorage.setItem('urlToken', to.query.token)
  //   try {
  //     const res = await logintest()
  //     console.log('file: index.js ~ line 140 ~ res', res)
  //     localStorage.setItem('userInfo', JSON.stringify(res.data.userinfo))

  //     // const a = await monitorAreas()
  //     // const checkedIndex = 0
  //     // let areas = []

  //     // if (a.code == 1 && a.data.areas.length > 0) {
  //     //   areas = a.data.areas
  //     //   localStorage.setItem('monitorAreas', JSON.stringify(areas))
  //     //   localStorage.setItem('monitorAreasCheckedIndex', checkedIndex)
  //     // }

  //     // if (areas.length === 0)
  //     //   Message({
  //     //     message: '矿区列表为空',
  //     //     type: 'warning',
  //     //   })
  //     next('/main-layout/home')
  //   } catch (error) {
  //     Message.error(error)
  //     next('/')
  //   }
  // }
  // let userInfo = JSON.parse(localStorage.getItem('userInfo'))

  // if (userInfo) {
  next();
  // } else {
  //   if (to.path === '/login') {
  //     next()
  //   } else {
  //     next(`/login?redirect=${to.path}`)
  //   }
  // }
});

export default router;
