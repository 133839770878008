<template>
  <div class="test-result-box">
    <el-tag v-if="form[componentKey] == 1" type="success">成功</el-tag>
    <el-tag v-else-if="form[componentKey] == 2" type="danger">失败</el-tag>
  </div>
</template>

<script>
export default {
  props: {
    componentKey: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style></style>
