<template>
  <GlobalTable
    class="table-wrap2"
    :highlightCurrentRow="true"
    :tableConfig="tableConfig"
    :tableColumn="tableColumn"
    :tableData="tableData"
    @onRowClick="onRowClick"
  >
    <template #handle="{ row }">
      <el-button type="text" @click="edit(row)">编辑</el-button>
      <el-popover
        placement="top"
        width="160"
        v-model="row.delVisible"
        popper-class="table-del-popper"
      >
        <p>确定删除吗？</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="row.delVisible = false"
            >取消</el-button
          >
          <el-button type="primary" size="mini" @click="deleteData(row)"
            >确定</el-button
          >
        </div>
        <el-button slot="reference" type="text" style="color: #f56c6c"
          >删除</el-button
        >
      </el-popover>
    </template>
  </GlobalTable>
</template>
<script>
import { getProtocolOrders, getProtocol } from "@/appApi/iDevice";

export default {
  // 协议指令表格
  components: {},
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    tableOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tableConfig: {
        selectionLength: 1,
      },
      tableColumn: [],
      tableData: [],
      showPagination: true,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.setTableColumn();
      this.getTableData();

      if (this.form.protocolorder_id) {
        this.setDefaultChecked();
      }
    },
    setDefaultChecked() {
      const defaultChecked = this.tableData.find(
        (val) => val.id == this.form.protocolorder_id
      );
      defaultChecked.checkedId = defaultChecked.id;

      this.$emit("onRowClick", defaultChecked, true);
      this.$nextTick(() => {
        this.scrollToRow(defaultChecked);
      });
    },
    // 滚动到选中行
    scrollToRow(row) {
      const index = this.tableData.findIndex((val) => val.id == row.id);
      const trHeight = $(
        ".table-wrap2 .el-table__body-wrapper .el-table__row"
      ).height();
      // const tableBody = $(".table-wrap2 .el-table__body-wrapper");
      const tableBody = document.querySelector(
        ".table-wrap2 .el-table__body-wrapper"
      );
      setTimeout(() => {
        tableBody.scrollTop = index * trHeight;
      }, 0);
    },
    onRowClick(row, column, event) {
      if (row.id == this.form.protocolorder_id) {
        row.checkedId = 0;
        this.form.protocolorder_id = "";
        return;
      }
      this.tableData.forEach((item) => {
        item.checkedId = row.id == item.id ? row.id : 0;
      });

      this.form.protocolorder_id = row.id;

      this.$emit("onRowClick", row);
    },
    getTableData() {
      if (!this.total) {
        const firstRequest = getProtocolOrders(this.form.protocol_id, "", 1, 1);
        this.total = firstRequest.data.page.dataCount;
      }

      const secondRequest = getProtocolOrders(
        this.form.protocol_id,
        "",
        1,
        this.total
      );

      let data = secondRequest.data.data;
      data.forEach((val) => {
        val.createtime = this.$Moment(val.createtime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        val.delVisible = false;
        val.checkedId = 0;
      });

      this.tableData = data;
    },
    setTableColumn() {
      const res = getProtocol(this.form.protocol_id);
      let type = 0;

      if (res.data) {
        type = res.data.type;
      }

      let tableColumn = [];
      const radioColumn = {
        prop: "checkedId",
        labelProp: "id",
        type: "radio",
        label: "选择",
        width: this.$FontSize(0.8),
      };

      // 通信协议类型,1=modbus,2=can,3=opc,4=预定义,5=自定义
      switch (type) {
        case 1:
          tableColumn = this.getModbusColumns();
          break;
        case 2:
          tableColumn = this.getCanColumns();
          break;
        case 3:
          tableColumn = this.getOpcColumns();
          break;
        case 4:
          tableColumn = this.getPredefinedColumns();
          break;
        case 5:
          tableColumn = this.getCustomColumns5();
          break;
        case 6:
          tableColumn = this.getCustomColumns6();
          break;
        default:
          tableColumn = this.getDefaultColumns();
          break;
      }

      this.tableColumn = [radioColumn, ...tableColumn];
    },
    getModbusColumns() {
      return [
        {
          prop: "index",
          type: "index",
          label: "序号",
          width: this.$FontSize(0.8),
        },
        {
          prop: "name",
          label: "指令名称",
          showTooltip: true,
        },
        {
          prop: "custom-column",
          name: "control_type",
          label: "指令类型",
        },
        {
          prop: "frame_order1",
          label: "设备地址",
        },
        {
          prop: "frame_order2",
          label: "功能码",
        },
        {
          prop: "frame_order3",
          label: "寄存器地址",
        },
        {
          prop: "frame_order4",
          label: "寄存器个数",
        },
        {
          prop: "frame_length",
          label: "数据区字节数",
        },
        {
          prop: "frame_content",
          label: "写入寄存器的数据",
          showTooltip: true,
          width: this.$FontSize(1.5),
        },
        {
          prop: "frame_checksum",
          label: "CRC校验码",
        },
        {
          prop: "detail",
          label: "描述",
          showTooltip: true,
        },
        {
          prop: "createtime",
          width: this.$FontSize(1.8),
          label: "创建时间",
        },
      ];
    },
    getCanColumns() {
      return [
        {
          prop: "index",
          type: "index",
          label: "序号",
          width: this.$FontSize(0.8),
        },
        {
          prop: "name",
          label: "指令名称",
          width: this.$FontSize(3),
          showTooltip: true,
        },
        {
          prop: "freamId",
          label: "帧ID",
          showTooltip: true,
        },
        {
          prop: "data",
          label: "数据",
          showTooltip: true,
        },
        {
          prop: "detail",
          label: "描述",
          width: this.$FontSize(1.5),
          showTooltip: true,
        },
        {
          prop: "createtime",
          label: "创建时间",
          width: this.$FontSize(1.8),
        },
      ];
    },
    getOpcColumns() {
      return [
        {
          prop: "index",
          type: "index",
          label: "序号",
          width: this.$FontSize(0.8),
        },
        {
          prop: "name",
          label: "指令名称",
          width: this.$FontSize(3),
          showTooltip: true,
        },
        {
          prop: "data",
          label: "数据",
          showTooltip: true,
        },
        {
          prop: "detail",
          label: "描述",
          width: this.$FontSize(1.5),
          showTooltip: true,
        },
        {
          prop: "createtime",
          label: "创建时间",
          width: this.$FontSize(1.8),
        },
      ];
    },
    getPredefinedColumns() {
      return [
        {
          prop: "index",
          type: "index",
          label: "序号",
          width: this.$FontSize(0.8),
        },
        {
          prop: "name",
          label: "指令名称",
          width: this.$FontSize(3),
          showTooltip: true,
        },
        {
          prop: "instruct",
          label: "指令",
          showTooltip: true,
        },
        {
          prop: "send",
          label: "发送类型",
          showTooltip: true,
        },
        {
          prop: "detail",
          label: "描述",
          width: this.$FontSize(1.5),
          showTooltip: true,
        },
        {
          prop: "createtime",
          label: "创建时间",
          width: this.$FontSize(1.8),
        },
      ];
    },
    getCustomColumns5() {
      return [
        {
          prop: "index",
          type: "index",
          label: "序号",
          width: this.$FontSize(0.8),
        },
        {
          prop: "name",
          label: "指令名称",
          width: this.$FontSize(3),
          showTooltip: true,
        },
        {
          prop: "frame_head",
          label: "帧头",
          showTooltip: true,
        },
        {
          prop: "frame_length",
          label: "长度",
          showTooltip: true,
        },
        {
          prop: "frame_order1",
          label: "指令",
          showTooltip: true,
        },
        {
          prop: "frame_content",
          label: "内容",
          showTooltip: true,
        },
        {
          prop: "frame_checksum",
          label: "校验和",
          showTooltip: true,
        },
        {
          prop: "frame_tail",
          label: "帧尾",
          showTooltip: true,
        },
        {
          prop: "detail",
          label: "描述",
          width: this.$FontSize(1.5),
          showTooltip: true,
        },
        {
          prop: "createtime",
          label: "创建时间",
          width: this.$FontSize(1.8),
        },
      ];
    },
    getCustomColumns6() {
      return [
        {
          prop: "index",
          type: "index",
          label: "序号",
          width: this.$FontSize(0.8),
        },
        {
          prop: "name",
          label: "指令名称",
          width: this.$FontSize(3),
          showTooltip: true,
        },
        {
          prop: "frame_content",
          label: "协议内容",
          width: this.$FontSize(1.5),
          showTooltip: true,
        },
        {
          prop: "detail",
          label: "描述",
          width: this.$FontSize(1.5),
          showTooltip: true,
        },
        {
          prop: "createtime",
          label: "创建时间",
          width: this.$FontSize(1.8),
        },
      ];
    },
    getDefaultColumns() {
      return [
        {
          prop: "index",
          type: "index",
          label: "序号",
          width: this.$FontSize(0.8),
        },
        {
          prop: "name",
          label: "指令名称",
          width: this.$FontSize(3),
          showTooltip: true,
        },
        {
          prop: "frame_content",
          label: "协议内容",
          width: this.$FontSize(1.5),
          showTooltip: true,
        },
        {
          prop: "detail",
          label: "描述",
          width: this.$FontSize(1.5),
          showTooltip: true,
        },
        {
          prop: "createtime",
          label: "创建时间",
          width: this.$FontSize(1.8),
        },
      ];
    },
    close() {
      this.$emit("onSubmit", false);
    },
  },
};
</script>
<style lang="scss">
.table-wrap2 {
  &.global-table {
    height: 300px;
  }
  // width: 100%;
}
</style>
