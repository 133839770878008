export default {
  state: () => ({
    // 接口类型列表 类型 PXI 1 /RS232 2 /RS485 2 / 3 RG45
    channel_type_list: [
      {
        label: "PXI",
        value: "1",
      },
      {
        label: "RS232",
        value: "2",
      },
      {
        label: "RS485",
        value: "3",
      },
      {
        label: "RG45",
        value: "4",
      },
    ],
    channel_type_map: {
      1: "PXI",
      2: "RS232",
      3: "RS485",
      4: "RG45",
    },
  }),
};
