<template>
  <div class="menu-box">
    <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
      <el-radio-button :label="false">展开</el-radio-button>
      <el-radio-button :label="true">收起</el-radio-button>
    </el-radio-group> -->
    <!-- <div class="left-title">
      <img src="@/assets/logo.png" alt="" />
      <span>垃圾分类系统</span>
    </div> -->
    <el-menu
      :default-active="defaultActive"
      :default-openeds="defaultOpeneds"
      class="el-menu-vertical-demo"
      router
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      background-color="transparent"
      active-text-color="#577CDF"
    >
      <el-menu-item
        index="/main-layout/home"
        v-if="[1, 2, 3, 4, 5].includes(group_id)"
      >
        <i class="el-icon-house"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="1" v-if="[2, 3, 4, 5].includes(group_id)">
        <template slot="title">
          <i class="el-icon-edit-outline"></i>
          <span>报送管理</span>
        </template>
        <el-menu-item
          index="/main-layout/monthly"
          v-if="[2, 3, 4].includes(group_id)"
          >月度报送</el-menu-item
        >
        <el-menu-item
          index="/main-layout/quarter"
          v-if="[2, 3, 4].includes(group_id)"
          >季度自查</el-menu-item
        >
        <el-menu-item
          index="/main-layout/department"
          v-if="[5].includes(group_id)"
          >部门月度报送</el-menu-item
        >
      </el-submenu>
      <el-submenu index="2" v-if="[1].includes(group_id)">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>评估管理</span>
        </template>
        <el-menu-item index="/main-layout/quarterly-evaluation"
          >季度评估管理</el-menu-item
        >
        <el-menu-item index="/main-layout/department-evaluation"
          >部门评估管理</el-menu-item
        >
      </el-submenu>
      <el-submenu index="3" v-if="[1].includes(group_id)">
        <template slot="title">
          <i class="el-icon-data-analysis"></i>
          <span>统计分析</span>
        </template>
        <el-menu-item index="/main-layout/overview">本市概况</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      group_id: JSON.parse(localStorage.getItem("userInfo")).group_id,
      defaultActive: "/main-layout/home",
      defaultOpeneds: [],
      // isCollapse: false,
      // menuList: [
      // {
      //   path: "/main-layout/home",
      //   name: "首页",
      //   show: true,
      //   iconClass: "el-icon-s-home",
      // },
      // {
      //   path: "/main-layout/user",
      //   name: "用户管理",
      //   show: true,
      //   iconClass: "el-icon-s-home",
      // },
      // {
      //   path: "/main-layout/device",
      //   name: "设备管理",
      //   show: true,
      //   iconClass: "el-icon-s-home",
      // },
      // {
      //   path: "/main-layout/car",
      //   name: "小车管理",
      //   show: true,
      //   iconClass: "el-icon-s-home",
      // },
      // ],
    };
  },
  computed: {
    ...mapState(["isCollapse"]),
    // isCollapse: {
    //   get() {
    //     return this.$store.state.isCollapse;
    //   },
    //   set() {
    //   },
    // },
  },
  created() {
    this.init();
  },
  watch: {
    //监听路由变化
    $route(to, from) {
      console.log("🚀 ~ $route ~ to:", to);

      const arr = ["/main-layout/monthly-details"];
      const arr2 = [
        "/main-layout/quarterly-evaluation-details",
        "/main-layout/department-evaluation-details",
      ];

      if (arr.includes(to.path)) {
        // this.defaultActive = "/main-layout/monthly";
        this.defaultActive = to.meta.defaultOpeneds;
        // this.defaultOpeneds = [to.meta.defaultOpeneds];
      } else if (arr2.includes(to.path)) {
        // this.defaultActive = "/main-layout/quarterly-evaluation";
        this.defaultActive = to.meta.defaultOpeneds;
        // this.defaultOpeneds = [to.meta.defaultOpeneds];
      } else {
        this.defaultActive = to.meta.defaultOpeneds;
      }
    },
  },
  methods: {
    init() {
      // const authIds = this.authIds.map((item) => item[0]);
      // const authMenus = [];

      // this.menuData.forEach((item) => {
      //   if (authIds.includes(item.id)) {
      //     authMenus.push(item);
      //   }
      // });

      if (this.$route.meta && this.$route.meta.defaultOpeneds) {
        this.defaultOpeneds = [this.$route.meta.defaultOpeneds];
        this.defaultActive = this.$route.meta.defaultOpeneds;
      }
      // this.menuList = [...this.menuList, ...authMenus];

      // this.defaultActive = this.$route.path;
      // const res = getMenus();
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="scss">
.menu-box {
  // width: 220px;
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    min-width: 200px;
  }
  .left-title {
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    @include flex(row, flex-start, center);
    span {
      font-size: 16px;
      color: #1d2129;
    }
    img {
      // width: 62px;
      height: 40px;
    }
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
    border-right: 0;
  }

  .el-submenu__title:hover {
    background: linear-gradient(
      91deg,
      rgba(65, 108, 220, 0.5),
      rgba(65, 108, 220, 0) 99%
    ) !important;
    border-radius: 4px;
  }

  .el-menu-item:hover {
    background: linear-gradient(
      91deg,
      rgba(65, 108, 220, 0.5),
      rgba(65, 108, 220, 0) 99%
    ) !important;
    border-radius: 4px;
  }
}
</style>
