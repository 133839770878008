export default {
  state: () => ({
    // 通信协议类型,1=modbus,2=can,3=opc,4=预定义,5=自定义
    auth_list: [
      {
        value: 1,
        label: "首页",
      },
      {
        value: 2,
        label: "测试需求描述",
      },
      {
        value: 3,
        label: "测试仪器描述",
      },
      {
        value: 4,
        label: "用户管理",
      },
      {
        value: 5,
        label: "系统管理",
      },
    ],
    auth_map: {
      1: "首页",
      2: "测试需求描述",
      3: "测试仪器描述",
      4: "用户管理",
      5: "系统管理",
    },
    // 数据类型：0=可使用协议，1=协议模板
    role_list: [
      {
        label: "超级管理员",
        value: 0,
      },
      {
        label: "需求工程师",
        value: 1,
      },
      {
        label: "实施工程师",
        value: 2,
      },
    ],
    role_map: {
      0: "超级管理员",
      1: "需求工程师",
      2: "实施工程师",
    },
  }),
};
