export default {
  state: () => ({
    // 通信协议类型,1=modbus,2=can,3=opc,4=预定义,5=自定义
    protocol_type_list: [
      {
        label: "MODBUS",
        value: 1,
      },
      {
        label: "CAN",
        value: 2,
      },
      {
        label: "OPC",
        value: 3,
      },
      {
        label: "预定义",
        value: 4,
      },
      {
        label: "自定义包协议",
        value: 5,
      },
      {
        label: "自定义字符协议",
        value: 6,
      },
    ],
    protocol_type_map: {
      1: "MODBUS",
      2: "CAN",
      3: "OPC",
      4: "预定义",
      5: "自定义包协议",
      6: "自定义字符协议",
    },
    // 数据类型：0=可使用协议，1=协议模板
    datatype_list: [
      {
        label: "可使用协议",
        value: 0,
      },
      {
        label: "协议模板",
        value: 1,
      },
    ],
    datatype_map: {
      0: "可使用协议",
      1: "协议模板",
    },
    controlTypeOptions: [
      {
        label: "直接发送",
        value: 0,
      },
      {
        label: "选值发送",
        value: 1,
      },
      {
        label: "写值发送",
        value: 2,
      },
    ],
    controlTypeMap: {
      0: "直接发送",
      1: "选值发送",
      2: "写值发送",
    },
  }),
};
