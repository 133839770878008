<template>
  <div id="app" :class="themeClass">
    <GlobalTransition>
      <router-view />
    </GlobalTransition>
  </div>
</template>

<script>
import theme from "@/mixins/theme";
export default {
  mixins: [theme],
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
