export default {
  state: () => ({
    // 接口类型列表
    interface_type_list: [
      {
        label: "未配置",
        value: 0,
      },
      {
        label: "串口232",
        value: 10,
      },
      {
        label: "串口485",
        value: 11,
      },
      {
        label: "虚拟串口",
        value: 12,
      },
      {
        label: "网口TCP",
        value: 20,
      },
      {
        label: "网口UDP",
        value: 21,
      },
      {
        label: "网口服务端TCP",
        value: 22,
      },
      {
        label: "MODBUS网络TCP",
        value: 23,
      },
      {
        label: "板卡PXI",
        value: 30,
      },
      {
        label: "板卡PXIe",
        value: 31,
      },
      {
        label: "板卡GPIB",
        value: 32,
      },
    ],
    interface_type_map: {
      0: "未配置",
      10: "串口232",
      11: "串口485",
      12: "虚拟串口",
      20: "网口TCP",
      21: "网口UDP",
      22: "网口服务端TCP",
      23: "MODBUS网络TCP",
      30: "板卡PXI",
      31: "板卡PXIe",
      32: "板卡GPIB",
    },
    // com波特率列表
    com_ratio_list: [
      {
        value: 110,
      },
      {
        value: 300,
      },
      {
        value: 600,
      },
      {
        value: 1200,
      },
      {
        value: 2400,
      },
      {
        value: 4800,
      },
      {
        value: 7200,
      },
      {
        value: 9600,
      },
      {
        value: 14400,
      },
      {
        value: 19200,
      },
      {
        value: 28800,
      },
      {
        value: 38400,
      },
      {
        value: 56000,
      },
      {
        value: 57600,
      },
      {
        value: 76800,
      },
      {
        value: 115200,
      },
      {
        value: 230400,
      },
      {
        value: 460800,
      },
    ],
    // com数据位列表
    com_dc_list: [
      {
        label: "1",
        value: 0,
      },
      {
        label: "2",
        value: 1,
      },
      {
        label: "3",
        value: 2,
      },
      {
        label: "4",
        value: 3,
      },
      {
        label: "5",
        value: 4,
      },
      {
        label: "6",
        value: 5,
      },
      {
        label: "7",
        value: 6,
      },
      {
        label: "8",
        value: 7,
      },
    ],
    com_dc_map: {
      0: "1",
      1: "2",
      2: "3",
      3: "4",
      4: "5",
      5: "6",
      6: "7",
      7: "8",
    },
    // com停止位列表
    com_sc_list: [
      {
        label: "1",
        value: 0,
      },
      {
        label: "1.5",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
    ],
    com_sc_map: {
      0: "1",
      1: "1.5",
      2: "2",
    },
    // com校验位列表
    com_cc_list: [
      {
        label: "none",
        value: 0,
      },
      {
        label: "Odd",
        value: 1,
      },
      {
        label: "Even",
        value: 2,
      },
      {
        label: "Mark",
        value: 3,
      },
      {
        label: "Space",
        value: 4,
      },
    ],
    com_cc_map: {
      0: "none",
      1: "Odd",
      2: "Even",
      3: "Mark",
      4: "Space",
    },
  }),
};
