<template>
  <div class="global-custom-card h100 flex flex-col">
    <div class="custom-title">
      <span class="title-box">
        {{ customTitle }}
      </span>
      <slot name="header-btns"></slot>
    </div>
    <div class="custom-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardSize: {
      type: String,
      default: "min-card",
    },
    customTitle: {
      type: String,
      default: "",
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    more: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss">
$h: px2vh(30);
.global-custom-card {
  position: relative;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.4);
  padding: 20px 20px 0;
  box-sizing: border-box;
  border-radius: 4px;

  .custom-title {
    // height: $h;
    position: relative;
    display: flex;
    box-sizing: border-box;
    margin-bottom: px2vh(20);

    .custom-title-icon {
      height: px2vh(14);
      margin-right: px2vh(10);
    }

    .title-box {
      display: flex;
      align-items: center;
      height: 100%;
      // flex: 1;
      // background: radial-gradient(
      //   80% 94% at 18% 0%,
      //   #408dff 0%,
      //   rgba(41, 78, 129, 0.2) 100%
      // );
      font-family: Source Han Sans;
      padding-left: px2vh(6);
      @include font4vh(rgba(34, 41, 50, 1), 14, 600);
      color: rgba(34, 41, 50, 1);
      font-size: 14px;
      font-weight: 600;
      text-shadow: 0px 2px 24px 0px #427ddc;
    }

    .more-btn {
      @include font4vh(#fff, 16, 500);
      position: absolute;
      right: 0;
      top: 0;
      height: $h;
      line-height: $h;
      padding-right: px2vh(20);
      cursor: pointer;
    }
  }
  .custom-body {
    padding: 0 px2vh(20) px2vh(20);
    box-sizing: border-box;
    flex: 1;
    height: 0;
    // overflow: hidden;
    // background: linear-gradient(
    //   90deg,
    //   rgba(31, 62, 102, 0.3) 0%,
    //   rgba(31, 62, 102, 0.8) 100%
    // );
    backdrop-filter: blur(16px);
    &::-webkit-scrollbar {
      display: none !important; /* Chrome Safari */
    }
  }
}
</style>
