import { get, post } from "../services/http";

// 消息列表 /api/question_bank/messageList
export function messageList(params = {}) {
  return get(`/api/question_bank/messageList`, params);
}

// 消息已读操作 /api/question_bank/messageRead
export function messageRead(params = {}) {
  return get(`/api/question_bank/messageRead`, params);
}
