// 滚动至底部
export default function (el) {
  const scrollWrap = $(el)[0];
  scrollWrap.scrollTop = scrollWrap.scrollHeight;
  console.log(
    "🚀 ~ file: scrollToBottom.js:4 ~ scrollWrap:",
    scrollWrap,
    scrollWrap.scrollTop
  );
}
