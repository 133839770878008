// 仪器数据
import _get from "./index";
//获取仪器列表
export function getDevices(name, pageCurrent, pageSize) {
  return _get("iDevice", "getDevices", name, pageCurrent, pageSize);
}
//获取仪器
export function getDevice(id) {
  return _get("iDevice", "getDevice", id);
}
//添加仪器
export function addDevice(json) {
  return _get("iDevice", "addDevice", json);
}
//修改仪器
export function modifyDevice(json) {
  return _get("iDevice", "modifyDevice", json);
}
//删除仪器
export function deleteDevice(json) {
  return _get("iDevice", "deleteDevice", json);
}

//查询接口
export function getInterfaces(name, pageCurrent, pageSize) {
  return _get("iDevice", "getInterfaces", name, pageCurrent, pageSize);
}
//添加接口
export function addInterface(json) {
  return _get("iDevice", "addInterface", json);
}
//修改接口
export function modifyInterface(json) {
  return _get("iDevice", "modifyInterface", json);
}
//删除接口
export function deleteInterface(json) {
  return _get("iDevice", "deleteInterface", json);
}

//查询协议
export function getProtocols(name, type, pageCurrent, pageSize) {
  return _get("iDevice", "getProtocols", name, type, pageCurrent, pageSize);
}
//查询协议
export function getProtocol(id) {
  return _get("iDevice", "getProtocol", id);
}
//添加协议
export function addProtocol(json) {
  return _get("iDevice", "addProtocol", json);
}
//修改协议
export function modifyProtocol(json) {
  return _get("iDevice", "modifyProtocol", json);
}
//删除协议
export function deleteProtocol(json) {
  return _get("iDevice", "deleteProtocol", json);
}

//查询协议指令
export function getProtocolOrders(protocol_id, name, pageCurrent, pageSize) {
  return _get(
    "iDevice",
    "getProtocolOrders",
    protocol_id,
    name,
    pageCurrent,
    pageSize
  );
}
//添加协议指令
export function addProtocolOrder(json) {
  return _get("iDevice", "addProtocolOrder", json);
}
//修改协议指令
export function modifyProtocolOrder(json) {
  return _get("iDevice", "modifyProtocolOrder", json);
}
//删除协议指令
export function deleteProtocolOrder(json) {
  return _get("iDevice", "deleteProtocolOrder", json);
}
//获取协议指令(选值发送)选项
export function getProtocolOrderItem(protocol_id) {
  return _get("iDevice", "getProtocolOrderItem", protocol_id);
}

//获取设备渠道列表
export function getDeviceChannels(name, pageCurrent, pageSize) {
  return _get("iDevice", "getDeviceChannels", name, pageCurrent, pageSize);
}
//获取设备渠道
export function getDeviceChannel(id) {
  return _get("iDevice", "getDeviceChannel", id);
}
//添加设备渠道
export function addDeviceChannel(json) {
  return _get("iDevice", "addDeviceChannel", json);
}
//修改设备渠道
export function modifyDeviceChannel(json) {
  return _get("iDevice", "modifyDeviceChannel", json);
}
//删除设备渠道
export function deleteDeviceChannel(id) {
  return _get("iDevice", "deleteDeviceChannel", id);
}
