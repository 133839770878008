import Login from "@/views/Login/Index";
import MainLayout from "@/views/MainLayout/Index";
import Page404 from "@/viewsAuth/Page404";

export default [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/main-layout",
    component: MainLayout,
    children: [
      {
        path: "home",
        name: "Home",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "首页"],
          defaultOpeneds: "/main-layout/home",
        },
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/Home/Index"),
      },
      {
        //月度报送
        path: "monthly",
        name: "Monthly",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "报送管理", "月度报送"],
          defaultOpeneds: "/main-layout/monthly",
        },
        component: () =>
          import(/* webpackChunkName: "Monthly" */ "@/views/Submitted/Monthly"),
      },
      {
        //月度报送详情页
        // path: "monthly-details/:dw_id/:monthtable_id/:refuse_classify_id/:sxtype/:page/:status/:issue_number",
        path: "monthly-details",
        name: "MonthlyDetails",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "报送管理", "月度报送", "报送详情"],
          defaultOpeneds: "/main-layout/monthly",
        },
        component: () =>
          import(
            /* webpackChunkName: "MonthlyDetails" */ "@/views/Submitted/MonthlyDetails"
          ),
      },
      {
        //季度自查
        path: "quarter",
        name: "Quarter",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "报送管理", "季度自查"],
          defaultOpeneds: "/main-layout/quarter",
        },
        component: () =>
          import(/* webpackChunkName: "Quarter" */ "@/views/Submitted/Quarter"),
      },
      {
        //季度自查详情页
        path: "quarter-details",
        name: "QuarterDetails",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "报送管理", "季度自查", "报送详情"],
          defaultOpeneds: "/main-layout/quarter",
        },
        component: () =>
          import(
            /* webpackChunkName: "QuarterDetails" */ "@/views/Submitted/QuarterDetails"
          ),
      },
      {
        //部门月度报送
        path: "department",
        name: "Department",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "报送管理", "部门月度报送"],
          defaultOpeneds: "/main-layout/department",
        },
        component: () =>
          import(/* webpackChunkName: "Department" */ "@/views/Submitted/Department"),
      },
      {
        //部门月度报送详情页
        path: "department-details",
        name: "DepartmentDetails",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "报送管理", "部门月度报送", "报送详情"],
          defaultOpeneds: "/main-layout/department",
        },
        component: () =>
          import(/* webpackChunkName: "DepartmentDetails" */ "@/views/Submitted/DepartmentDetails"),
      },

      {
        //季度评估列表
        path: "quarterly-evaluation",
        name: "QuarterlyEvaluation",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "评估管理", "季度评估管理"],
          defaultOpeneds: "/main-layout/quarterly-evaluation",
        },
        component: () =>
          import(
            /* webpackChunkName: "QuarterlyEvaluation" */ "@/views/Estimate/QuarterlyEvaluation"
          ),
      },
      {
        //季度评估垃圾分类报送详情页
        // path: "monthly-details/:dw_id/:monthtable_id/:refuse_classify_id/:sxtype/:page/:status/:issue_number",
        path: "quarterly-evaluation-details",
        name: "QuarterlyEvaluationDetails",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "评估管理", "季度评估管理", "垃圾分类报送详情"],
          defaultOpeneds: "/main-layout/quarterly-evaluation",
        },
        component: () =>
          import(
            /* webpackChunkName: "QuarterlyEvaluationDetails" */ "@/views/Submitted/MonthlyDetails"
          ),
      },
      {
        //季度评估环卫报送详情页
        // path: "monthly-details/:dw_id/:monthtable_id/:refuse_classify_id/:sxtype/:page/:status/:issue_number",
        path: "quarterly-evaluation-details2",
        name: "QuarterlyEvaluationDetails2",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "评估管理", "季度评估管理", "环卫报送详情"],
          defaultOpeneds: "/main-layout/quarterly-evaluation",
        },
        component: () =>
          import(
            /* webpackChunkName: "QuarterlyEvaluationDetails2" */ "@/views/Submitted/MonthlyDetails"
          ),
      },
      {
        //部门评估管理列表
        path: "department-evaluation",
        name: "DepartmentEvaluation",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "评估管理", "部门评估管理"],
          defaultOpeneds: "/main-layout/department-evaluation",
        },
        component: () =>
          import(
            /* webpackChunkName: "DepartmentEvaluation" */ "@/views/Estimate/DepartmentEvaluation"
          ),
      },
      {
        //部门评估月度报送详情页
        path: "department-details2",
        name: "DepartmentDetails2",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "报送管理", "部门评估管理", "部门报送详情"],
          defaultOpeneds: "/main-layout/department",
        },
        component: () =>
          import(/* webpackChunkName: "DepartmentDetails" */ "@/views/Submitted/DepartmentDetails"),
      },
      {
        //本市概况
        path: "overview",
        name: "Overview",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "统计分析", "本市概况"],
          defaultOpeneds: "/main-layout/overview",
        },
        component: () =>
          import(
            /* webpackChunkName: "Overview" */ "@/views/StatisticAnalysis/Overview"
          ),
      },
      {
        //消息列表
        path: "message",
        name: "Message",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "消息通知"],
          defaultOpeneds: "/main-layout/message",
        },
        component: () =>
          import(/* webpackChunkName: "Message" */ "@/views/Message/Index"),
      },
      {
        //信息维护
        path: "information",
        name: "Information",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "信息维护"],
          defaultOpeneds: "/main-layout/information",
        },
        component: () =>
          import(
            /* webpackChunkName: "Information" */ "@/views/Information/Index"
          ),
      },
      {
        //安全管理
        path: "safe",
        name: "Safe",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "安全管理"],
          defaultOpeneds: "/main-layout/safe",
        },
        component: () =>
          import(/* webpackChunkName: "Safe" */ "@/views/Safe/Index"),
      },
      {
        //修改手机
        path: "change-mobile",
        name: "ChangeMobile",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "安全管理", "修改手机"],
          defaultOpeneds: "/main-layout/safe",
        },
        component: () =>
          import(/* webpackChunkName: "ChangeMobile" */ "@/views/ChangeMobile/Index"),
      },
      {
        //修改密码
        path: "change-password",
        name: "ChangePassword",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "安全管理", "修改密码"],
          defaultOpeneds: "/main-layout/safe",
        },
        component: () =>
          import(/* webpackChunkName: "ChangePassword" */ "@/views/ChangePassword/Index"),
      },
      {
        //安全日志
        path: "safe-log",
        name: "SafeLog",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "安全管理", "安全日志"],
          defaultOpeneds: "/main-layout/safe",
        },
        component: () =>
          import(/* webpackChunkName: "SafeLog" */ "@/views/SafeLog/Index"),
      },
      {
        //操作日志
        path: "operation-log",
        name: "OperationLog",
        meta: {
          icon: "",
          breadcrumb: ["工作台", "安全管理", "操作日志"],
          defaultOpeneds: "/main-layout/safe",
        },
        component: () =>
          import(/* webpackChunkName: "OperationLog" */ "@/views/OperationLog/Index"),
      },
    ],
  },
  { path: "*", name: "404页面", component: Page404 },
];
