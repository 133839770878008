import toFormData from "./toFormData";
import downloadByUrl from "./downloadByUrl";
import getUID from "./getUid";
import scrollToBottom from "./scrollToBottom";

export default {
  getBaseUrl() {
    return process.env.NODE_ENV === "development" ? "proxy" : "";
  },
  toFormData,
  downloadByUrl,
  getUID,
  scrollToBottom,
};
